import { createBrowserRouter } from 'react-router-dom'
import AppLayout from '../layouts/AppLayout'
import Home from '../pages/Home'
import PageNotFound from '../pages/PageNotFound/PageNotFound'
import { routePath } from './route.constant'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    errorElement: <PageNotFound />,
    children: [
      {
        path: routePath.HOME,
        element: <Home />,
      },
    ],
  },
])
