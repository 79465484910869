import { MutedOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { Player } from '@lottiefiles/react-lottie-player'
import React, { useRef, useState } from 'react'
import Gift from './components/Gift'
import { HomePageWrapper } from './styled'
import { isMobile } from 'react-device-detect'

function Home() {
  const audioRef = useRef()
  const audioBgRef = useRef()
  const [isHiddenBox, setIsHiddenBox] = useState(false)
  const [isPlayAudio, setIsPlayAudio] = useState(false)

  const handleClick = () => {
    setIsHiddenBox(true)
    audioRef.current.play()
  }

  return (
    <HomePageWrapper>
      {isHiddenBox ? (
        <Player
          autoplay
          loop
          className="max-h-screen"
          src={`./json/${isMobile ? 'balloon' : 'new-balloon'}.json`}
        ></Player>
      ) : (
        <div className={`absolute top-[230px] lg:top-[520px]`}>
          <Gift onClick={handleClick} />
        </div>
      )}
      <div
        onClick={() => {
          setIsPlayAudio(!isPlayAudio)
          !isPlayAudio ? audioBgRef.current.play() : audioBgRef.current.pause()
        }}
        className="absolute right-8 top-8 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-white"
      >
        {!isPlayAudio ? (
          <PlayCircleOutlined className="text-[20px]" />
        ) : (
          <MutedOutlined className="text-[20px]" />
        )}
      </div>

      <audio ref={audioRef} className="hidden">
        <source src="/audio/notice.mp3" type="audio/mpeg" />
      </audio>
      <audio ref={audioBgRef} className="hidden">
        <source src="/audio/happy-birthday-song.mp3" type="audio/mpeg" />
      </audio>
    </HomePageWrapper>
  )
}

export default Home
