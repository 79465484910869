import React from 'react'

function Gift({ onClick }) {
  return (
    <div
      onClick={onClick}
      className="flex cursor-pointer flex-col items-center justify-center gap-5"
    >
      <img src="./image/giphy.gif" alt="" width={150} />
      <div className="text-3xl font-bold text-yellow-400 lg:text-[#99bcff]">
        {' '}
        Tap to open
      </div>
    </div>
  )
}

export default Gift
