import styled from 'styled-components'

export const HomePageWrapper = styled.div`
  position: relative;
  background-image: url('./image/bg-pc.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 640px) {
    background-position: center;
    background-image: url('./image/bg-mobile.jpg');
    background-size: cover;
  }
`
